import { log } from './logger';
import packageJson from '../../package.json';
const apiUrl = process.env.REACT_APP_API_URL;

// logEvent.js
let userIP = null; // This will store the userIP

// Function to set the user IP once and reuse it
export const setUserIP = (ip) => {
  userIP = ip;
};

// logEvent.js
export const logEvent = (eventType, eventLabel, eventValue = null) => {
  try {
      // Make a POST request to the Flask backend to log the event
      log('Logging event:', eventType, eventLabel, eventValue);
      fetch(`${apiUrl}/backend/trigger_event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_ip: userIP,
          event_type: eventType,
          event_label: eventLabel,
          event_value: eventValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          log('Event logged successfully:', data);
        })
        .catch((error) => {
          console.error('Error logging event:', error);
        });
      } catch (error) {
        console.error('Error logging event:', error);
      }
  };
  

/* -------------------------------------------------------------------------- */
/*                                USER METADATA                               */
/* -------------------------------------------------------------------------- */

const getPageLoadTime = () => {
  const [navigationEntry] = performance.getEntriesByType('navigation');
  if (navigationEntry) {
    return navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime;
  }
  return 'unknown';
};

export const collectUserMetadata = async () => {
    // Collect basic browser info
    const basicInfo = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
      bandwidth: navigator.connection ? navigator.connection.downlink : 'unknown',
      memoryUsage: navigator.deviceMemory || 'unknown',
      referrer: document.referrer,
      currentUrl: window.location.href,
      touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
      pageLoadTime: getPageLoadTime(), 
      reactAppVersion: packageJson.version,
    };
  
    // Get IP-based geolocation
    try {
      const response = await fetch('https://ipapi.co/json/');
      const geoData = await response.json();
      const geoInfo = {
        ip: geoData.ip,
        city: geoData.city,
        region: geoData.region,
        country: geoData.country_name,
        latitude: geoData.latitude,
        longitude: geoData.longitude,
      };
  
      return { ...basicInfo, ...geoInfo };
    } catch (error) {
      console.error('Error fetching geolocation:', error);
      return basicInfo;
    }
  };