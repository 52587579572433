import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


// ! change this between test and dev
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY);

const cardElementOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({ onPaymentSuccessful }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
      setPaymentStatus('Payment failed. Please try again.');
      setIsProcessing(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/backend/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payment_method: paymentMethod.id }),
      });

      const paymentIntent = await response.json();
      const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: paymentMethod.id
      });

      if (result.error) {
        setPaymentStatus('Payment failed. Please try again.');
      } else if (result.paymentIntent.status === 'succeeded') {
        setPaymentStatus('Payment successful!');
        onPaymentSuccessful(); // Call the callback function
      }
    } catch (err) {
      console.error(err);
      setPaymentStatus('An error occurred. Please try again.');
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h2>Payment Details</h2>
      <div className="form-group">
        <div className="card-element-container">
          <CardElement options={cardElementOptions} />
        </div>
      </div>
      <button
        type="submit"
        disabled={!stripe || isProcessing || paymentStatus.includes('successful')}
        className={isProcessing ? 'processing' : ''}
      >
        {isProcessing ? 'Processing...' : 'Pay $1'}
      </button>
      {paymentStatus && (
        <div className={`payment-status ${paymentStatus.includes('successful') ? 'success' : 'error'}`}>
          {paymentStatus}
        </div>
      )}
    </form>
  );
};

const Payment = ({ onPaymentSuccessful }) => {
  return (
    <div className="payment-container">
      <Elements stripe={stripePromise}>
        <CheckoutForm onPaymentSuccessful={onPaymentSuccessful} />
      </Elements>
    </div>
  );
};

export default Payment;