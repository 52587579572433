import React from 'react';
import { log } from '../scripts/logger';

class UserInputArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      areaContent: "",
      iconEnabled: false,
    };
  }

  // on icon click setSelectedTopic with textarea content
  onIconClick = () => {
    const textArea = document.getElementById('refinement_prompt');
    const topic = textArea.value;
    if (topic.trim() !== "") {
      this.props.setAppState({ selectedTopic: topic });
      log("User topic", topic);
    }
  };

  // Update iconEnabled based on textarea value
  onTextAreaChange = (event) => {
    const text = event.target.value;
    this.setState({ iconEnabled: text.trim() !== "", areaContent: text });
  };

  // Handle keydown event for "Enter" key
  handleKeyDown = (event) => {
    if (event.key === "Enter" && this.state.iconEnabled) {
      event.preventDefault(); // Prevent default Enter behavior (e.g., new line in textarea)
      this.onIconClick(); // Trigger the action on Enter key press
    }
  };

  render() {
    const iconClass = this.state.iconEnabled ? "icon icon_enabled" : "icon icon_disabled";
    
    const selected = this.state.areaContent;
    const selectedTopic = this.props.appState.selectedTopic;
    const isSelectedTopic = selected && selected === selectedTopic;
    const areaClass = isSelectedTopic ? "textarea_selected_topic" : "";

    const showClass = this.props.appState.topics.length === 0 ? "" : "show"

    return (
      <div className={`suggest_inputs ${showClass}`}>
        <div className="input_title" style={{'marginBottom':"0.5rem"}}>Alternatively, describe a topic you'd like to dig into.</div>
        <textarea
          className={areaClass}
          type="text"
          id="refinement_prompt"
          rows="4"
          placeholder="Describe a topic you'd like to find papers about."
          onChange={this.onTextAreaChange}  // Listen to changes
          onKeyDown={this.handleKeyDown}  // Listen for Enter key
        />
        <span className={iconClass} onClick={this.state.iconEnabled ? this.onIconClick : null}>
          <div className="icon_text">Submit topic</div>
          <i className="fa-solid fa-chevron-right"></i>
        </span>
      </div>
    );
  }
}

export default UserInputArea;
