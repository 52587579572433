import React, { useState } from 'react';



const GroupBox = ({
  titleNum,
  title,
  description,
  helpContent,
  groupBoxID,
  groupID,
  children, 
  hideTitle,
  hideHelpEntirely,
  groupStyle,
}) => {
  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => {
    setShowHelp(prevShowHelp => !prevShowHelp);
  };

  const hideHelp = () => {
    setTimeout(() => {
      setShowHelp(false);
    }, 300);
  }

  const helpMessageClass = showHelp ? "show" : "";

  return (
    <div className="group_box" onMouseLeave={hideHelp} style={groupStyle} id={groupBoxID}>
      {!hideTitle &&
        <div className="group_title non-selectable-text" id={groupID}><strong>{titleNum}</strong> {title}</div>
    }
      {description && <div 
          className="group_description non-selectable-text"
          dangerouslySetInnerHTML={{__html: description}}
      >
        {/* {description} */}
      </div>}
      {!hideHelpEntirely && <>
        <div className="help_button" onClick={toggleHelp}>?</div>
        <div className={`group_help ${helpMessageClass}`}>
          <strong>Instructions: </strong>
          {helpContent}
        </div>
        </>
      }
      <div className="group_content">
        {children} 
      </div>
    </div>
  );
};


export default GroupBox;
