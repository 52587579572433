import { useState } from 'react';

// Define possible states
// const STATES = ["not started", "working on it", "done", "error"];

function useStateManager(initialStates) {
    const [states, setStates] = useState(initialStates);
  
    // Method to update multiple states at once
    const setMultipleStates = (updates) => {
      setStates((prevStates) => ({
        ...prevStates,
        ...updates,
      }));
    };

    return { states, setMultipleStates };
  }

export default useStateManager;