import React from 'react';



class SelectedTopicDisplay extends React.Component {
  render() {
    const selected = this.props.appState.selectedTopic !== "";
    const topicTitleClass = selected ? "selected_topic_title" : "selected_topic_title inactive";
    return (
      // <div className="columns">
      // <div className="column is-half"></div>
      // <div className="column is-half" style={
      //       {"padding-bottom":"0", "margin":"0", "height":"fit-content"}
      //   }>
        <div className="selected_topic_display non-selectable-text" id="selected_topic_display">
          <div className={topicTitleClass}>
            {selected ? "Selected topic:" : "No topic selected yet"}
          </div>
          {selected && <p className="selected_topic_text">
            {this.props.appState.selectedTopic}
          </p>}
        </div>
      // </div>
      // </div>
    );
  }
}


export default SelectedTopicDisplay;