import React, { useState, useEffect } from 'react';

const StatusMessage = ({ message, status }) => {
  const [timer, setTimer] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [stopTime, setStopTime] = useState(null);

  useEffect(() => {
    if (status === 'working on it') {
      setStartTime(Date.now());
      const interval = setInterval(() => {
        setTimer(Date.now() - startTime);
      }, 10);

      return () => clearInterval(interval);
    } else {
      setStopTime(Date.now());
      setStartTime(null);
    }
  }, [status, startTime]);

  let statusIcon;
  if (status === "working on it") {
    statusIcon = <i className="fa-solid fa-hourglass-start busy_icon"></i>
  } else if (status === "done") {
    statusIcon = <i className="fa-solid fa-check"></i>
  } else if (status === "error") {
    statusIcon = <i className="fa-solid fa-exclamation"></i>
  } else {
    statusIcon = <i></i>
  }

  const isActive = status === "working on it" || status === "done" || status === "error";
  const color = isActive ? 'var(--active_text)' : 'var(--inactive_text)';
  const opacity = isActive ? 1 : 0.5;
  const style = { 'color': color, 'opacity': opacity };
  const errorMessage = status === "error" ? " -- something went wrong, sorry" : "";

  const showTimer = status === "working on it" || status === "done" || status === "error";
  const timerValue = showTimer ? (timer / 1000).toFixed(2) : (stopTime - startTime) / 1000;

  return (
    <li style={style}>
      <span className="icon">{statusIcon}</span>
      <strong style={style}>{message}</strong>
      {showTimer && (
        <span className="timer">
          <span className="icon-small">
            <i className="fa-solid fa-stopwatch"></i>
          </span>
          {timerValue}s
        </span>
      )}
      {errorMessage}
    </li>
  );
};

const StatusDisplay = ({ divID, appStateDict }) => {
  const isActive = (status) => {
    return status && status !== 'not started' && status !== 'done';
  };

  const shouldDisplay = Object.values(appStateDict).some(status => isActive(status));
  const boardClass = shouldDisplay ? "show" : "";

  return (
    <div className={`status_board ${boardClass}`} id={divID}>
      <ul>
        {Object.entries(appStateDict).map(([message, status], index) => (
          <StatusMessage key={index} message={message} status={status} />
        ))}
      </ul>
    </div>
  );
};

export default StatusDisplay;