import React, { Component } from 'react';
import sendPostRequest from '../scripts/Requests';
import { logEvent } from '../scripts/logEvent';
import { log } from '../scripts/logger';

class BibtexLoader extends Component {
  _handleFileLoad = async (event) => {   
    // reset everything
    this.props.setAppState(this.props.initialState);

    try {
      // update user
      this.props.setAppState({ loadingBib: 'working on it' });
      log('Loading papers...');

      // get papers
      const papers = await this.fetchPapers(event);
      const label = this.props.isDefault ? 'default' : 'user';
      logEvent('click', `load ${label} bibtext`, papers.length);

      // check if tooo many refs were uploaded
      if (papers && papers.length > this.props.maxPapers) {
        log(`Too many papers: ${papers.length} - trimming to ${this.props.maxPapers}`);
        this.props.setAppState({ shouldWarnBibSize: true });
        papers.splice(this.props.maxPapers);
      }

      // process papers
      this.props.setAppState({
        loadingBib: 'done',
        analyzingReferences: 'working on it'
      });
      const processed_papers = await this.processPapers(papers);

      log("... done", processed_papers);
      this.props.setAppState({
        analyzingReferences: 'done',
        references: processed_papers
      });


    } catch (error) {
      logEvent('error', 'load bibtext', error);
      console.error("Error loading papers:", error);
    }
  };

  get handleFileLoad() {
    return this._handleFileLoad;
  }

  set handleFileLoad(value) {
    this._handleFileLoad = value;
  }

  processPapers = async (papers) => {
    log('Processing papers...');

    const response = await sendPostRequest(
      `${this.props.apiUrl}/backend/paperdetails`,
      JSON.stringify({papers: papers}),
      'Processing papers',
      { 'Content-Type': 'application/json' }
    );

    if (response && response.result) {
      return response.result;
    } else {
      logEvent('error', 'process papers', response);
      console.error('Error processing papers:', response);
      this.props.setAppState({ analyzingReferences: 'error' });
    }
  }

  fetchPapers = async (event) => {
    const { apiUrl, isDefault } = this.props;

    // get file and parse content in backend
    try {
      // default files
      if (isDefault) {
        log(`${apiUrl}/backend/default-file`);
        const response = await fetch(`${apiUrl}/backend/default-file`);
        if (response && response.ok) {
          return await response.json();
        }
        throw new Error('Failed to load default file');
      } else {

        // user BIB file
        const file = event.target.files[0];
        log(file);
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          const response = await sendPostRequest(
            `${apiUrl}/backend/upload`,
            formData,
            'Uploading user file'
          );
          if (response && response.result) {
            return response.result;
          }
        }
      }
    } catch (error) {
      logEvent('error', 'load bibtext', error);
      console.error("Error fetching papers:", error);
      this.props.setAppState({ loadingBib: 'error' });
      throw error;
    }
  }

  render() {
    const { isDefault } = this.props;
    const buttonClass = isDefault ? "refy_button button_white" : "refy_button button_red";
    const buttonText = isDefault ? "Use our example" : "Load your file";

    return (
      <label 
        className={buttonClass}
        onClick={isDefault ? this.handleFileLoad : null}
      >
        {!isDefault && <input type="file" onChange={this.handleFileLoad} accept='.bib' />}
        {buttonText}
      </label>
    );
  }
}

export default BibtexLoader;
