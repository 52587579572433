import React from 'react';
import sendPostRequest from '../scripts/Requests';
import { log } from '../scripts/logger';
import { logEvent } from '../scripts/logEvent';
import RelevanceInput from './RelevanceInput';

class Paper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAbstract: false, // Initial state: abstract is hidden
      checkboxChecked: false,
      abstractClicked: false,
      tldrClicked: false,
      relevanceClicked: false,
      relevance: "",
      relevanceScore: "",
    };
  }

  // Open the paper's URL in a new tab if available
  openPaperLink = () => {
    if (this.props.url) {
      const logDetails = {
        title: this.props.title,
        url: this.props.url,
        topic: this.props.appState.selectedTopic,
        abstract: this.props.abstract,
      }
      logEvent('click', 'open paper link', logDetails);
      window.open(this.props.url, '_blank', 'noopener noreferrer');
    }
  }

  updateCheckboxState = (newState) => {
    this.setState({ checkboxChecked: newState });
  }


  handleCheckboxChange = (e) => {
    e.stopPropagation(); // Prevent the checkbox click from triggering the link

    this.setState(prevState => ({
      checkboxChecked: !prevState.checkboxChecked
    }));

    // callback to update parent
    this.props.checkboxCallback(this.props.index, !this.state.checkboxChecked);    
  }

  toggleAbstractClicked = async () => {
    this.setState({ abstractClicked: !this.state.abstractClicked });    
  }
  
  toggleTldrClicked = async () => {
    this.setState({ tldrClicked: !this.state.tldrClicked });
  }

  toggleRelevanceClicked = async () => {
    if (this.props.appState.selectedTopic === "") {
      log("No topic selected - can't get relevance");
      this.setState({ relevanceClicked: false });
      return;
    }

    this.setState({ relevanceClicked: !this.state.relevanceClicked });

    if (this.state.relevance === ""){

      // get relevance from LLM in backend
      const relevance = await sendPostRequest(
        `${this.props.apiUrl}/backend/paperrelevance`,
        JSON.stringify({ 
          abstract: this.props.abstract,
          title: this.props.title,
          topic: this.props.appState.selectedTopic, 
        }),
        'Getting paper relevance description',
        { 'Content-Type': 'application/json' }
      );

      if (relevance && relevance.result) {
        const logDetails = {
          paper_title: this.props.title,
          paper_abstract: this.props.abstract,
          topic: this.props.appState.selectedTopic,
          relevance: relevance.result.relevance,
          relevance_score: relevance.result.score,
        }
        logEvent('relevance', 'generated', logDetails);
        this.setState({ relevance: relevance.result.relevance, relevanceScore: relevance.result.score });
      } else {
        logEvent('error', 'get relevance', relevance);
        console.error('Error fetching relevance:', relevance);
      }
    }

  }
  

  render() {
    // control checkbox style
    const chckedStyle = {'background': 'var(--checked-background)'};
    const uncheckedStyle = {'background': 'var(--unchecked-background)'};
    const checkBoxStyle = this.state.checkboxChecked ? chckedStyle : uncheckedStyle;

    // control elements visibility
    const abstractClass = this.state.abstractClicked ? "show" : "";
    const relevanceClass = this.state.relevanceClicked ? "show" : "";
    const tldrClass = this.state.tldrClicked ? "show" : "";
    const paperContainerClass = this.state.abstractClicked || this.state.tldrClicked || this.state.relevanceClicked ? "expanded" : "";
   
    return (   
      <div className={`paper_container ${paperContainerClass}`}>
        <div className="paper_checkbox"
          onClick={this.handleCheckboxChange}
          style={checkBoxStyle}
        ></div>


        <div className="paper">
          <div className="paper_title" onClick={this.openPaperLink}>
            {this.props.url && (
              <span className="icon">
                <i className="fa-solid fa-link"></i>
              </span>
            )}
            {this.props.title}
          </div>
          <div className="details columns">
              <div className="year column is-2"> {this.props.year} </div>
              <div className="journal column is-7-dekstop"> {this.props.journal}</div>
              {this.props.citations ? 
                <div className="citations column is-1"> citations: <strong>{this.props.citations}</strong></div> : 
                <div className="citations column is-1"> </div>
              }
          </div>
          <div className="author">{this.props.author}</div>

        {/* -------------------------------- controls --------------------------------  */}
        <div className="paper_buttons">
          {this.props.appState.selectedTopic !== "" &&
              <div 
              className={`paper_button non-selectable-text ${this.state.relevanceClicked && "active" }`}
              onClick={this.toggleRelevanceClicked}
              >
              Relevance
            </div>
          }
            {this.props.abstract && <div 
              className={`paper_button non-selectable-text ${this.state.abstractClicked && "active" }`}
              onClick={this.toggleAbstractClicked}
              >Abstract</div>
            }

            {this.props.tldr && 
              <div 
                className={`paper_button non-selectable-text ${this.state.tldrClicked && "active" }`}
                onClick={this.toggleTldrClicked}
                >TLDR</div>
            }
          </div>
          
          { /* ---------------------- relevance, abstract and tldr ----------------------  */}
          <div className={`abstract ${relevanceClass}`}>
              <div>
                <div className="sub_title"><strong>Paper relevance</strong></div>
                {this.state.relevance === "" ? (
                  <>
                    <div className="relevance_placeholder" style={{"width":"90%"}}>RELEVANCE</div>
                    <div className="relevance_placeholder" style={{"width":"95%"}}>RELEVANCE</div>
                    <div className="relevance_placeholder" style={{"width":"82%"}}>RELEVANCE</div>
                    <div className="relevance_placeholder" style={{"width":"88%"}}>RELEVANCE</div>
                  </>
                ) : (
                  <>
                  <div className="relevance_description" style={{'marginBottom':'0.2rem', 'marginTop':'0.6rem'}}>Relevance score: <strong style={{'marginRight':'0.1em'}}>{this.state.relevanceScore}</strong>/10</div>
                  <div className="relevance_description">{this.state.relevance}</div>
                  <RelevanceInput
                    apiUrl={this.props.apiUrl}
                    paperTitle={this.props.title}
                    paperAbstract={this.props.abstract}
                    LLMRelevanceScore={this.state.relevanceScore}
                    LLMRelevanceScoreExplanation={this.state.relevance}
                  />
                  </>
                )}
                
              </div>
            </div>



            <div className={`abstract ${abstractClass}`}>
              {this.props.abstract ? (
                <div>
                  <div className="sub_title"><strong>Abstract</strong></div>
                  <div>{this.props.abstract}</div>
                </div>
              ) : (
                <div>No abstract available</div>
              )}
            </div>


            <div className={`tldr ${tldrClass}`}>
              {this.props.tldr ? (
                <div>
                  <div className="sub_title"><strong>TL-DR</strong></div>
                  <div>{this.props.tldr}</div>
                </div>
              ) : (
                <div>No TLDR available</div>
              )}
            </div>
            
        </div>
      </div>
    );
  }
}


class EmptyPaper extends React.Component {
  render() {    
      return (
      <div className={`paper empty_paper ${this.props.id}`}>
        <div className="paper_title"></div>
        <div className="details">
          <div className="year"></div>
          <div className="journal"></div>
        </div>
        <div className="author"></div>
        <div className="author"></div>
      </div>
    );
  }
}

export { Paper, EmptyPaper };