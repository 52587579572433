import React, {useState, useEffect} from 'react';
import HeadTags from '../components/HeadTags';
import GroupBox from '../components/GroupBox';
import sendPostRequest from '../scripts/Requests';
import { collectUserMetadata } from '../scripts/logEvent';
import NotificationBanner from '../components/NotificationBanner';
import Payment from '../components/Payment';




/* -------------------------------------------------------------------------- */
/*                                  TextField                                 */
/* -------------------------------------------------------------------------- */

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOk: false,
      isWarning: false,
  }
}

  render() {
    const {
      label,
      placeholder,
      leftIcon,
      rightIcon,
      type = "text", // Default to "text" if type is not provided
      min,
      max,
      onChange,
      onBlur
    } = this.props;

    const { isOk, isWarning } = this.state;

    const statusLabel = isWarning ? 'is-warning' : (isOk ? 'is-success' : '');
    const helpOpacity = isWarning ? "1" : "0";

    return (
      <div className="field">
        <label className="label">{label}
          {this.props.required && <span className="required">*</span>}
        </label>
        <div className="control has-icons-left has-icons-right">
          <input 
            id={label.toLowerCase().replace(/ /g, '_')}
            className={`input ${statusLabel} ${this.props.inputClassName}`} 
            type={type} 
            placeholder={placeholder} 
            min={min} 
            max={max}
            onChange={onChange} 
            onBlur={onBlur} 
          />
          {leftIcon && (
            <span className="icon is-small is-left">
              <i className={leftIcon}></i>
            </span>
          )}
          {rightIcon && (
            <span className="icon is-small is-right">
              <i className={rightIcon}></i>
            </span>
          )}
            {this.props.warningMessage && <p 
                  className="help is-danger" style={{"opacity":helpOpacity}}
                >
                  {this.props.warningMessage}
                </p>
        }
        </div>
      </div>
    );
  }
}


/* -------------------------------------------------------------------------- */
/*                                 EMAIL FIELD                                */
/* -------------------------------------------------------------------------- */

const EmailField = ({ label, placeholder, onValidation,inputClassName }) => {
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [isEmail1Valid, setIsEmail1Valid] = useState(true);
  const [isEmail2Valid, setIsEmail2Valid] = useState(true);
  const [doEmailsMatch, setDoEmailsMatch] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmail1Change = (e) => {
    const value = e.target.value;
    setEmail1(value);
    setIsEmail1Valid(validateEmail(value));
  };

  const handleEmail2Change = (e) => {
    const value = e.target.value;
    setEmail2(value);
    setIsEmail2Valid(validateEmail(value));
    setDoEmailsMatch(value === email1);
  };

  useEffect(() => {
    const isValid = isEmail1Valid && isEmail2Valid && doEmailsMatch && email1 !== '';
    onValidation(isValid);
  }, [isEmail1Valid, isEmail2Valid, doEmailsMatch, email1, onValidation]);

  return (
    <div className="email-field">
      <label className="label">{label}<span className="required">*</span></label>
      <div className="control has-icons-left has-icons-right">
        <input
          id="email"
          className={`input ${!isEmail1Valid && email1 !== '' ? 'is-danger' : ''} ${inputClassName}`}
          type="email"
          placeholder={placeholder}
          value={email1}
          onChange={handleEmail1Change}
        />
        <span className="icon is-small is-left">
          <i className='fas fa-envelope'></i>
        </span>
        {isEmail1Valid && email1 && (
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        )}
        {!isEmail1Valid && email1 !== '' && (
          <p className="help is-danger">Please enter a valid email address</p>
        )}
      </div>
      <div className="control mt-2 has-icons-left has-icons-right">
        <input
          className={`input ${(!isEmail2Valid || !doEmailsMatch) && email2 !== '' ? 'is-danger' : ''}`}
          type="email"
          placeholder="Confirm email"
          value={email2}
          onChange={handleEmail2Change}
        />
        <span className="icon is-small is-left">
          <i className='fas fa-envelope'></i>
        </span>
        {isEmail2Valid && doEmailsMatch && email2 && (
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        )}
        {!isEmail2Valid && email2 !== '' && (
          <p className="help is-danger">Please enter a valid email address</p>
        )}
        {isEmail2Valid && !doEmailsMatch && email2 !== '' && (
          <p className="help is-danger">Emails do not match</p>
        )}
        {isEmail2Valid && isEmail1Valid && doEmailsMatch && email1 !== '' && email2 !== '' && (
          <p className="help is-success">Emails match</p>
        )}
      </div>
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                                    page                                    */
/* -------------------------------------------------------------------------- */

const InterestFormApp = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    institution: '',
    referenceManager: '',
    searchFrequency: '',
    searchSources: '',
    searchMethods: '',
    interestRating: '',
    featuresDescription: '',
  });
  const [formError, setFormError] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [notificationState, setNotificationState] = useState({
    show: false,
    title: '',
    message: '',
    isError: false
  });
  const [missingFields, setMissingFields] = useState([]);

  const validateForm = () => {
    const newMissingFields = [];
    if (!formData.name) newMissingFields.push('name');
    if (!emailValid) newMissingFields.push('email');
    if (!formData.interestRating) newMissingFields.push('interestRating');
    
    setMissingFields(newMissingFields);

    if (newMissingFields.length > 0) {
      setFormError('Please fill in all required fields.');
      return false;
    }
    setFormError('');
    return true;
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
    if (missingFields.includes(id)) {
      setMissingFields(prev => prev.filter(field => field !== id));
    }
  };

  const handleInterestRatingChange = (e) => {
    let value = Math.min(Math.max(parseFloat(e.target.value) || 0, 0), 10);
    setFormData(prevData => ({
      ...prevData,
      interestRating: value.toString(),
    }));
    if (missingFields.includes('interestRating')) {
      setMissingFields(prev => prev.filter(field => field !== 'interestRating'));
    }
  };



  const showNotification = (title, message, isError = false) => {
    setNotificationState({
      show: true,
      title,
      message,
      isError
    });
    setTimeout(() => setNotificationState(prev => ({ ...prev, show: false })), 2100);
  };

  const sendFeedback = () => {
    const feedbackData = { ...formData };

    sendPostRequest(
      `${apiUrl}/backend/send_telegram`,
      JSON.stringify({ message: feedbackData, header: "Lit Search Feedback" }),
      'Sending feedback by message',
      { 'Content-Type': 'application/json' }
    );

    collectUserMetadata().then(userMetadata => {
      sendPostRequest(
        `${apiUrl}/backend/save_interest_feedback`,
        JSON.stringify({ feedback: feedbackData, metadata: userMetadata }),
        'Saving feedback',
        { 'Content-Type': 'application/json' }
      );
    });
  };

  const handleTrialSignUp = () => {
    if (validateForm()) {
      setShowPaymentForm(true);
    }
  };

  const handleUpdateSignUp = () => {
    if (validateForm()) {
      setIsFeedbackSent(true);
      sendFeedback();
      showNotification(
        "Thank you!",
        "Your feedback has been sent and you've been signed up for updates.",
        false
      );
    }
  };

  const onPaymentSuccessful = () => {
    try {
      sendPostRequest(
        `${apiUrl}/backend/confirm_trial_signup`,
        JSON.stringify({ 
          email: formData.email,
          name: formData.name,
          interest_rating: formData.interestRating
        }),
        'Confirming trial signup',
        { 'Content-Type': 'application/json' }
      );
      showNotification(
        "Thank you!",
        "Your trial signup is complete.",
        false
      );
      setShowPaymentForm(false);
      setIsPaymentSuccessful(true);
    } catch (error) {
      console.error('Error confirming trial signup:', error);
      showNotification(
        "There was a problem processing the payment",
        "Please try again or contact us for assistance.",
        true
      );
    } 
  };

  const isFormValid = formData.interestRating && emailValid && formData.name;
  const signupButtonClass = (!isFormValid || isPaymentSuccessful) ? 'button_red_disabled' : '';
  const feedbackButtonClass = isFeedbackSent ? 'button_white_disabled' : '';

  return (
    <>
      <NotificationBanner 
        title={notificationState.title}
        message={notificationState.message}
        isError={notificationState.isError}
        triggerNotification={notificationState.show}
      />

      <div className="trial_container non-selectable-text">
        <div className="trial_label">Sign up for the <strong>Lit Search</strong> trial</div>
        <div className="trial_description">
          <b className="highlight">Get Lit Search for 3 months for just $1.</b><br/>
          By reserving your trial now, you'll have access to all of Lit Search's features for 3 months immediately
          after launch,<br/> and you'll be the first to hear when Lit Search is live!
        </div>
      </div>

      <div className="interest_page columns">
        <HeadTags />

        <div className="column is-half-desktop">
          <div className="about_container non-selectable-text">
             {/* ------------------------------ about -----------------------------  */}
             <div className="trial_label">
                Welcome to <strong>
                <img className="inline_image" src="logo.png" alt="Description" />
                Lit Search
                <img className="inline_image" src="logo.png" alt="Description" />
                </strong>.
              </div>
                <p>
                Lit search aims to be the most efficient way to find papers that match your interests.
                Whether you're a researcher, student, or just someone who loves to read, Lit Search
                will help you find the papers you need, based on the papers you've already read.
                </p>
                
                <p>
                <b className="about_bold">Why Lit Search? </b>
                  Traditional ways to find papers can be time-consuming and inefficient.
                  You would typically search for papers using keywords and spend hours sifting through
                  piles or irrelevant papers or references you've already read.
                  <br/><strong>We think that there should be a better system.</strong>
                </p>

                <br/>
                <p>
                  Lit Search uses an innovative algorithm to leverage the wealth of information 
                  represented by the papers you've already read to find and select new papers 
                  that are most relevant to you. It'll save you time and help you find the 
                  key papers that will spark new ideas and insights.
                </p>
                

                <p>
                  <b className="about_bold">How does it work?</b>
                  We're working to develop a truly personalized paper recommendation system.
                  Once Lit Search is live, you'll be able to gain access to all its features. 
                  There will be a free tier to give you the opportunity to test our system, and 
                  a paid subscription will unlock all of Lit Search's awesome features.
                </p>


                <p>
                  <b className="about_bold">Contact us</b> 
                  Write to <strong>contact-at-litsearch.io</strong> if you have any questions or feedback.
                </p>
          </div>
        </div>

        <div className="column">
          <GroupBox hideTitle={true} hideHelpEntirely={true} groupBoxID="interest_form_container">
            <div className="form_header_label">
              {(isPaymentSuccessful || isFeedbackSent) ? "Thank you!" : "Tell us about you."}
            </div>
            {!isPaymentSuccessful && (
              <>
                <TextField 
                  label="Name" 
                  placeholder="Name & Surname" 
                  leftIcon="fas fa-user"
                  id="name"
                  onChange={handleInputChange}
                  required={true}
                  inputClassName={missingFields.includes('name') ? 'isdanger' : ''}
                />
                <TextField 
                  label="Institution or Company" 
                  placeholder="Your institution" 
                  leftIcon="fa-solid fa-building-columns"
                  id="institution"
                  onChange={handleInputChange}
                />
                <EmailField
                  label="Email Address"
                  placeholder="Enter your email"
                  onValidation={setEmailValid}
                  onChange={(value) => setFormData(prevData => ({ ...prevData, email: value }))}
                  inputClassName={missingFields.includes('email') ? 'isdanger' : ''}
                />
                <div className="form_divider"/>
                
                <TextField 
                  label="Which reference manager do you use?" 
                  placeholder="Paperpile | Zotero | none..." 
                  id="referenceManager"
                  onChange={handleInputChange}
                />
                <TextField 
                  label="How often do you look for new papers?" 
                  placeholder="Once a week. Once a year..." 
                  id="searchFrequency"
                  onChange={handleInputChange}
                />
                <TextField 
                  label="Where do you look for papers" 
                  placeholder="Google scholar, ArXiv ..." 
                  id="searchSources"
                  onChange={handleInputChange}
                />
                <div className="field">
                  <label className="label">How do you normally find new papers?</label>
                  <div className="control">
                    <textarea 
                      className="textarea" 
                      placeholder="Keyword search, Google scholar alerts, Twitter..."
                      id="searchMethods"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form_divider"/>
          
                <TextField 
                  label="Rate your interest in a paper recommendation system" 
                  placeholder="On a scale of 1 to 10"
                  type="number" 
                  min="1" 
                  max="10"
                  id="interestRating"
                  required={true}
                  onChange={handleInterestRatingChange}
                  onBlur={handleInterestRatingChange}
                  inputClassName={missingFields.includes('interestRating') ? 'isdanger' : ''}
                />

                <div className="field">
                  <label className="label">Describe features you'd expect in a paper recommendation system.</label>
                  <div className="control">
                    <textarea 
                      className="textarea" 
                      placeholder="Describe features here"
                      id="featuresDescription"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                  
                <div 
                  className={`refy_button button_red signup_button ${signupButtonClass}`}
                  onClick={handleTrialSignUp}
                >
                  Sign up for the trial!
                </div>

                {!showPaymentForm && (
                  <>
                    <div className="or">or</div>
                    <div 
                      className={`refy_button button_white send_feedback_button ${!isFormValid ? 'button_white_disabled' : ''} ${feedbackButtonClass}`}
                      onClick={handleUpdateSignUp}
                    >
                      Sign up for updates
                    </div>
                  </>
                )}

                {showPaymentForm && (
                  <Payment onPaymentSuccessful={onPaymentSuccessful} />
                )}

                {formError && <p className="help is-danger">{formError}</p>}
              </>
            )}
          </GroupBox>
        </div>
      </div>
    </>
  );
};

export default InterestFormApp;