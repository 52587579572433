import React from 'react';
import { logEvent } from '../scripts/logEvent';

class RelevanceInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    toggleShow = () => {
        this.setState({ show: !this.state.show });
    }

    manageScore = (event) => {
        const scoreArea = document.getElementById('relevance_input_number');
        const score = event.target.value;

        if (score < 0 ) {
            scoreArea.value = 0;
        } else if (score > 10) {
            scoreArea.value = 10;
        } else if (isNaN(score)) {
            scoreArea.value = "";
        } else {
            scoreArea.value = score;
        }
    }


    submitFeedback = () => {
        // get text from textarea
        const textArea = document.getElementById('relevance_input_text');
        const scoreArea = document.getElementById('relevance_input_number');

        const logDetails = {
            user_explanation: textArea.value,
            user_score: scoreArea.value,
            paper_title: this.props.paperTitle,
            paper_abstract: this.props.paperAbstract,
            llm_score: this.props.LLMRelevanceScore,
            llm_score_explanation: this.props.LLMRelevanceScoreExplanation,
        };
        logEvent("relevance", 'relevance_feedback', logDetails);

        // hide
        setTimeout(() => {
            this.setState({ show: false })
        }, 200);

    }

    render() {
        const inputsClass = this.state.show ? "show" : "";

        return (
            <>
            
            <p className="relevance_input_toggle"
                onClick={this.toggleShow}>
                   {this.state.show ?
                    "Hide feedback form" :
                        "Disagree with our relevance score? Tell us what it should be!"
                        
                    } 
            </p>
            <div className={`relevance_input ${inputsClass}`}>
                <textarea
                    className="relevance_input_number"
                    type="number"
                    min="0"
                    max="10"
                    interval="1"
                    id="relevance_input_number"
                    rows="1"
                    placeholder="Write your score here"
                    onBlur={this.manageScore}

                />
                <textarea
                    className="relevance_input_text"
                    type="text"
                    id="relevance_input_text"
                    rows="2"
                    placeholder="Justify your score here"
                />

                <div 
                    className="refy_button button_grey relevance_input_button"
                    onClick={this.submitFeedback}
                >
                        Submit feedback
                </div>

            </div>
            </>
        );
        }
}

export default RelevanceInput;