import React from 'react';
import sendPostRequest from '../scripts/Requests';
import { log } from '../scripts/logger';
import { logEvent } from '../scripts/logEvent';

class ReferencesControl extends React.Component {
  async componentDidUpdate(prevProps) {
    const references = this.props.appState.references;

    if (prevProps.appState.references !== references) {
      // disable recs button
      if (references.length === 0) {
        return;
      }

      // Respond to new data
      log("New user references data:", references);
      this.resetPlaceholders();
      this.animatePlaceholders();
      await this.getTopics(references);

    }
  }

  getTopics = async (papers) => {
    this.props.setAppState({
      extractingTopics: 'working on it',
    });
    const promptDiv = document.getElementById('suggested_prompts');

    // get topics in backend
    const refinements = await sendPostRequest(
      `${this.props.apiUrl}/backend/topics`,
      JSON.stringify({ data: papers }),
      'Getting prompt suggestions',
      { 'Content-Type': 'application/json' }
    );

    // check if topics were extracted
    if (refinements && refinements.result) {
      // update display of overall references description
      promptDiv.innerHTML = refinements.result.description;

      // update topics storage and update uiser
      this.props.setAppState({
        extractingTopics: 'done',
        topics: refinements.result.topics,
      });

      // logs
      log('Description:', refinements.result.description);  
      log('Topics:', refinements.result.topics); 
    } else {
      logEvent('error', 'get refinements', refinements);
      console.error('Error fetching refinements:', refinements);
      this.props.setAppState({
        extractingTopics: 'error',
      });
    }
  }

  resetPlaceholders = () => {
    const promptDiv = document.getElementById('suggested_prompts');
    promptDiv.innerHTML = `
      <div class="references_summary_display_placeholder" style="width: 100%;"></div>
      <div class="references_summary_display_placeholder" style="width: 95%;"></div>
      <div class="references_summary_display_placeholder" style="width: 92%;"></div>
      <div class="references_summary_display_placeholder" style="width: 98%;"></div>
    `;
    
    const topicsDiv = document.getElementById('topics_placeholders_container');
    if (topicsDiv) {
      topicsDiv.innerHTML = `
        <div class="topic_placeholder" style="width: 100%;">TOPIC</div>
        <div class="topic_placeholder" style="width: 95%;">TOPIC</div>
        <div class="topic_placeholder" style="width: 92%;">TOPIC</div>
        <div class="topic_placeholder" style="width: 98%;">TOPIC</div>
        <div class="topic_placeholder" style="width: 96%;">TOPIC</div>
      `;
    }
  }

  animatePlaceholders = () => {
      const animateElements = (selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element, index) => {
          element.style.animation = 'fadeCycle 2s infinite ease-in-out';
          element.style.animationDelay = `${index * 0.1}s`;
        });
      };

      animateElements('.references_summary_display_placeholder');
      animateElements('.topic_placeholder');
    }

  isActive = (status) => {
    return status === "working on it" || status === "error" || status === "in-progress";
  }

  render() {
    return (
      <></>
    )
  }
}

export default ReferencesControl;