import React from 'react';
import InterestFormApp from './pages/InterestFormApp';
import MainApp from './pages/MainApp';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const subdomain = window.location.hostname.split('.')[0];

  return (
    <Router>
      <Routes>
        {subdomain === 'interest' ? (
          <Route path="/" element={<InterestFormApp />} />
        ) : (
          <Route path="/" element={<MainApp />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;