import React, { useState, useEffect } from 'react';

const NotificationBanner = ({ title, message, isError, triggerNotification }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (triggerNotification) {
      setVisible(true);
      console.log('Notification triggered');
      setTimeout(() => {
        setVisible(false);
      }, 2000); // Hide after 2 seconds
    }
  }, [triggerNotification]);

  return (
    <div>
      <div
        id="notification-banner"
        className={`notification-banner ${visible ? 'visible' : ''} 
            ${isError ? 'error' : ''}`}
      >
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default NotificationBanner;
