import React from 'react';
import sendPostRequest from '../scripts/Requests';
import { QueryParamSelector, QueryYearsSelect } from './QueryParamSelector';
import { logEvent } from '../scripts/logEvent';
import { log } from '../scripts/logger';

/* ------------------------------ quick search ------------------------------ */
async function doQuickSearch(appState, setAppState, apiUrl) {
  logEvent('search', 'quicksearch');
  
  // reset state
  setAppState({
      gettingRecs: 'working on it',
      sortingRecs: 'not started',
      generatingQueries: 'not started',
      queries: [],
      showRecs: true,
      recomendations: [],
      selectedTopic: '',
    })

  // scroll after a delay
  setTimeout(async () => {
      document.getElementById('recs_divline').scrollIntoView({ behavior: 'smooth',});
      // this.animatePlaceholders();
  }
  , 500);
    
  // Send request to get suggested papers
  log('Starting quicksearch');
  const suggestion = await sendPostRequest(
    `${apiUrl}/backend/quicksuggest`,
    JSON.stringify({
      references: appState.references,
    }),
    'Quick suggestion',
    { 'Content-Type': 'application/json' }
  );

  // Update suggested papers view
  if (suggestion) {
    log('Suggested papers:', suggestion.result);
    setAppState({ 
          gettingRecs: 'done',
          recomendations: suggestion.result,
          showRecs: true,
      });
  }
}


/* -------------------------- rec search functions -------------------------- */
async function getSearchQueries(apiUrl, selectedTopic, setAppState) {
  // get a list of queries for the topic
  const _queries = await sendPostRequest(
    `${apiUrl}/backend/queries`,
    JSON.stringify({ topic: selectedTopic }),
    'Getting prompt suggestions',
    { 'Content-Type': 'application/json' }
  );

  if (_queries && _queries.result) {
    setAppState({
      queries: _queries.result,
      generatingQueries: 'done',
      gettingRecs: 'working on it',
    });
  } else {
    logEvent('error', 'get queries', _queries);
    console.error('Error getting queries:', _queries);
    setAppState({
      queries: [],
      generatingQueries: 'error',
    })
  }
  log('Queries for topic:', _queries.result);
  return _queries;
}

async function getSuggestions(apiUrl, _queries, references, setAppState) {
  // Get start/end year value
  const startYear = document.getElementById('start_year_selector').value;
  const endYear = document.getElementById('end_year_selector').value;

  // Send request to get suggested papers
  log('Getting suggested papers...');
  const suggestion = await sendPostRequest(
    `${apiUrl}/backend/suggest`,
    JSON.stringify({
      queries: _queries.result,
      references: references,
      start_year: startYear,
      end_year: endYear,
    }),
    'Getting suggested papers',
    { 'Content-Type': 'application/json' }
  );

  // Update suggested papers view
  if (suggestion && suggestion.result.length > 0) {
    log('Suggested papers:', suggestion.result);
    setAppState({ 
      gettingRecs: 'done',
      recomendations: suggestion.result,
      showRecs: true,
    });
    return suggestion;
  } else {
    logEvent('error', 'get suggestions', suggestion);
    console.error('Error getting suggestions:', suggestion);
    setAppState({ gettingRecs: 'error' });
    return null
  }
}

async function sortSuggestions(apiUrl, references, suggestion, maxSuggestions, selectedTopic, setAppState) {
      // skip if suggestion is null
      if (!suggestion) {
        return {result: []};
      }

      const nSuggestions = document.getElementById('n_suggestions_to_retun').value;

      // Score and sort recs based on relevance - backend
      setAppState({sortingRecs: "working on it"});
      const sortedSuggestion = await sendPostRequest(
        `${apiUrl}/backend/score`,
        JSON.stringify({
          references: references,
          suggestions: suggestion.result,
          n_suggestions_to_retun: nSuggestions,
          max_suggestions: maxSuggestions,
          topic: selectedTopic,
        }),
        'Sorting suggestions',
        { 'Content-Type': 'application/json' }
      );

      if (sortedSuggestion && sortedSuggestion.result.length > 0) {
        log('Sorted suggestions', sortedSuggestion.result);
        setAppState({ 
          recomendations: sortedSuggestion.result,
          sortingRecs: "done",
        });
      } else {
        logEvent('error', 'sort suggestions', sortedSuggestion);
        console.error('Error sorting suggestions:', sortedSuggestion);
        setAppState({ sortingRecs: "error" });
      }
      
}

/* -------------------------------------------------------------------------- */
/*                                QUERY CONTROL                               */
/* -------------------------------------------------------------------------- */

class QueryControl extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      showQuickLabel: false,
      showRecLabel: false,
    }
  }

  handleRecommendPapers = async () => {
    // reset state
    this.props.setAppState({
      gettingRecs: 'not started',
      sortingRecs: 'not started',
      generatingQueries: 'working on it',
      queries: [],
      showRecs: true,
      recomendations: []
    })

    // scroll after a delay & animate placeholders
    setTimeout(async () => {
      document.getElementById('recs_divline').scrollIntoView({ behavior: 'smooth',});
      this.animatePlaceholders();
    }
    , 500);
    
    // log event
    const isUserTopic = !this.props.appState.topics.includes(this.props.appState.selectedTopic);
    const logDetails = {
      topic: this.props.appState.selectedTopic,
      isUserTopic: isUserTopic,
      non_selected_topics: this.props.appState.topics.filter(t => t !== this.props.appState.selectedTopic),
      search_queries: this.props.appState.queries,
      n_references: this.props.appState.references.length,
      references: this.props.appState.references.map(r => ({title: r.title, abstract: r.abstract})),
    }
    logEvent('search', 'recommend papers', logDetails);

    // get a list of queries for the topic
    const _queries = await getSearchQueries(this.props.apiUrl, this.props.appState.selectedTopic, this.props.setAppState);

    //  get references
    const suggestion = await getSuggestions(this.props.apiUrl, _queries, this.props.appState.references, this.props.setAppState);
  
    // sort suggestions
    await sortSuggestions(
      this.props.apiUrl, this.props.appState.references, suggestion, this.props.maxSuggestions, 
      this.props.appState.selectedTopic, this.props.setAppState
    );  
  }

  animatePlaceholders = () => {
    const animateElements = (selector) => {
      const elements = document.querySelectorAll(selector);
      log('Animating elements:', elements);
      elements.forEach((element, index) => {
        element.style.animation = 'fadeCycle 2s infinite ease-in-out';
        element.style.animationDelay = `${index * 0.1}s`;
      });
    };

    animateElements('.empty_paper');
  }


  render() {
    const gettingRecs = this.props.appState.gettingRecs;
    const sortingRecs = this.props.appState.sortingRecs;
    const isBusy = gettingRecs === 'working on it' || sortingRecs === 'working on it';
    const isEnabled = this.props.appState.selectedTopic !== '' && !isBusy;
    const buttonClass = !isEnabled
      ? "refy_button button_red_disabled button_wide"
      : "refy_button button_red button_wide";

    const busyBottonMessage = gettingRecs === "working on it" ? "Fetching recommendations" : "Sorting recommendations";
    const buttonMessage = isBusy ? busyBottonMessage : "Recommend papers";


    const quickLabelStyle = this.state.showQuickLabel ? {opacity: 1, transform: 'translateY(0)'} : {opacity: 0, transform: 'translateY(-0.5rem)'};
    const recLabelStyle = this.state.showRecLabel ? {opacity: 1, transform: 'translateY(0)'} : {opacity: 0, transform: 'translateY(-0.5rem)'};
    const quickSearchButtonEnabled = this.props.appState.references.length > 0;
    const quickSearchButtonClass = quickSearchButtonEnabled ? "" : "button_white_disabled";

    return (
      <>
        
        {/* ----------------------------- query controls ----------------------------- */}
        <div className="query_controls_container">
        {/* <div className="divline" id=""/> */}
        <div className="columns">
          <div className="column is-half">
                  <div className="quick_search" 
                    onMouseEnter={() => this.setState({showQuickLabel: true})}
                    onMouseLeave={() => this.setState({showQuickLabel: false})}
                  >
                  <div className={`refy_button button_white ${quickSearchButtonClass} quicksearch_button`}
                        onClick={() => doQuickSearch(
                              this.props.appState, this.props.setAppState, this.props.apiUrl
                        )}
                    >
                      Start quick search
                  </div>
                  </div>
              <div className="query_control_label" style={quickLabelStyle}>
            <strong>Quick search</strong> finds papers that are similar to your references without focusing on a specific topic.
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="query_controls">
              <QueryYearsSelect />
              <QueryParamSelector label="# papers" placeholder="25" id="n_suggestions_to_retun" />
            </div>

            <label
              className={buttonClass}
              id="suggest_button"
              onClick={this.handleRecommendPapers}
              onMouseEnter={() => this.setState({showRecLabel: true})}
              onMouseLeave={() => this.setState({showRecLabel: false})}
            >
              {buttonMessage}
            </label>

            <div className="query_control_label"  style={recLabelStyle}>
              <strong>Recommend search</strong> does an in-depth search for papers related to the topic you've selected.
            </div>
          </div>

          </div>
        </div>
      </>
    );
  }
}

export default QueryControl;